import React, {
  CSSProperties,
  ReactNode,
  RefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  autoPlacement,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useTranslation } from "next-i18next";

import Portal from "@sellernote/_shared/src/components/Portal";
import {
  UserPort,
  WareHouse,
} from "@sellernote/_shared/src/types/common/common";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import InputSearch, {
  InputSearchProps,
} from "@sellernote/_sds-v2/src/components/form/InputSearch";
import Loading from "@sellernote/_sds-v2/src/components/Loading";
import ExclamationTriangleIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExclamationTriangleIcon";
import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { InputSearchWithCategoryPortOption } from "./types";

import { SEARCH_RESULT_STATE } from "./constants";
import Styled from "./index.styles";

type PortInfoType = UserPort | WareHouse | undefined;
interface InputProps
  extends Pick<
    InputSearchProps,
    | "onBlur"
    | "onFocus"
    | "onReset"
    | "onKeyDown"
    | "placeholder"
    | "labelInfo"
    | "leftIconInfo"
    | "errorMessage"
  > {
  className?: string;
  width?: CSSProperties["width"];
  onSelect: (portInfo: PortInfoType) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  focusHandlerRef?: RefObject<{ focusInput: () => void }>;
}

type FloatingProps = {
  width: string;
  style: CSSProperties;
  ref: (node: HTMLElement | null) => void;
} & Record<string, unknown>;

interface OptionItemProps<T> {
  option: T;
  searchTerm: string;
  onSelect: (portInfo: PortInfoType) => void;
  highlightMatch: (
    label: string,
    searchTerm: string
  ) => string | (string | JSX.Element)[];
}

interface PanelProps<T> {
  searchSourceList: T[];
  renderPanel: ({
    option,
    searchTerm,
    onSelect,
    highlightMatch,
  }: OptionItemProps<T>) => ReactNode;
}
interface InputSearchWithPortOptionsProps {
  searchTerm: string;
}

export type {
  InputProps,
  FloatingProps,
  InputSearchWithPortOptionsProps,
  PortInfoType,
  OptionItemProps,
};

type Props<T> = InputSearchWithPortOptionsProps & InputProps & PanelProps<T>;

export default function InputSearchWithPortOptions<
  T extends InputSearchWithCategoryPortOption<string | number>
>({
  searchTerm,
  searchSourceList,
  renderPanel,

  width,
  className,
  placeholder,
  leftIconInfo,
  labelInfo,
  errorMessage,

  onChange,
  onReset,
  onBlur,
  onFocus,
  onKeyDown,
  onSelect,
  focusHandlerRef,

  isLoading,
}: Props<T>) {
  const { t } = useTranslation();

  const { isMobile } = useCheckIsMobile();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocus, setIsFocus] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    open: isFocus,
    onOpenChange: setIsFocus,
    middleware: [
      autoPlacement({
        allowedPlacements: ["bottom-start"],
        autoAlignment: false,
      }),
    ],
  });

  const dismiss = useDismiss(context, {
    // 오버플로우 영역을 스크롤하면 옵션리스트가 닫히는 조건 활성화 (모바일 IOS 대응)
    ancestorScroll: isMobile,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  /** 인풋요소 기준으로 fixed 스타일 계산 */
  const searchResultOptionWidth = (() => {
    const selectOptionElementRect =
      refs.reference.current?.getBoundingClientRect();

    return `${selectOptionElementRect?.width || 0}px`;
  })();

  const handleFocus = () => {
    onFocus?.();

    setIsFocus(true);
  };

  const handleSelect = useCallback(
    (portInfo: PortInfoType) => {
      onSelect(portInfo);

      setIsFocus(false);
    },
    [onSelect]
  );

  const highlightMatch = useCallback((label: string, searchTerm: string) => {
    if (!searchTerm) return label;

    const regex = new RegExp(`(${searchTerm})`, "gi");

    const parts = label.split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? (
        <Styled.highlightMatch key={i}>{part}</Styled.highlightMatch>
      ) : (
        part
      )
    );
  }, []);

  const commonFloatingProps = useMemo(() => {
    return {
      width: searchResultOptionWidth,
      ref: refs.setFloating,
      ...getFloatingProps(),
      style: floatingStyles,
    };
  }, [searchResultOptionWidth, refs, floatingStyles, getFloatingProps]);

  const optionItemProps = useMemo(
    () => ({
      searchTerm,
      onSelect: handleSelect,
      highlightMatch,
    }),
    [searchTerm, handleSelect, highlightMatch]
  );

  const hasSearchSourceList = Boolean(searchSourceList?.length);

  const isEmptySearchSourceList = (() => {
    if (!hasSearchSourceList) return false;

    return searchSourceList.every((option) => option.optionList.length === 0);
  })();

  const searchResultState = useMemo(() => {
    if (isLoading) return SEARCH_RESULT_STATE.LOADING;

    if (isEmptySearchSourceList) return SEARCH_RESULT_STATE.EMPTY;

    return SEARCH_RESULT_STATE.HAS_RESULTS;
  }, [isLoading, isEmptySearchSourceList]);

  useImperativeHandle(focusHandlerRef, () => ({
    focusInput: () => {
      inputRef.current?.focus();
    },
  }));

  return (
    <Styled.container
      ref={refs.setReference}
      {...getReferenceProps()}
      width={width}
      className={`${className ? className : ""} input-search-with-port-options`}
    >
      <InputSearch
        inputRef={inputRef}
        labelInfo={labelInfo}
        leftIconInfo={leftIconInfo}
        onInputValueChange={onChange}
        onReset={onReset}
        onBlur={onBlur}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        inputValue={searchTerm}
        placeholder={
          placeholder ||
          t("components:InputSearchWithPortOptions.항구/공항을_입력해주세요.")
        }
        errorMessage={errorMessage}
      />

      <Portal selector="#app-portal">
        {isFocus && searchResultState === SEARCH_RESULT_STATE.LOADING && (
          <Styled.loading {...commonFloatingProps}>
            <Loading
              direction="row"
              message={t(
                "components:InputSearchWithPortOptions.검색결과_로드중..."
              )}
            />
          </Styled.loading>
        )}

        {isFocus &&
          hasSearchSourceList &&
          searchResultState === SEARCH_RESULT_STATE.HAS_RESULTS && (
            <Styled.optionList {...commonFloatingProps}>
              {searchSourceList?.map((option) =>
                renderPanel({ ...optionItemProps, option })
              )}
            </Styled.optionList>
          )}

        {isFocus && searchResultState === SEARCH_RESULT_STATE.EMPTY && (
          <Styled.emptySearchResult {...commonFloatingProps}>
            <ExclamationTriangleIcon
              width={20}
              height={20}
              color={TEXT_COLOR.black_disabled}
            />

            <p>
              {t("components:InputSearchWithPortOptions.검색결과가_없습니다.")}
            </p>
          </Styled.emptySearchResult>
        )}
      </Portal>
    </Styled.container>
  );
}
