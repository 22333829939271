import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import {
  GET_USER_PORT_LIST_REQ,
  GET_USER_PORT_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/common";
import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import { UserPort } from "@sellernote/_shared/src/types/common/common";

import {
  InputSearchWithCategoryPortOption,
  InputSearchWithPortOption,
  PortCategory,
} from "../types";

type InputSearchPortOption<T> =
  | InputSearchWithPortOption<T>
  | InputSearchWithCategoryPortOption<T>;

export type { InputSearchPortOption };

interface UseGetPortOptionsProps<T> extends GET_USER_PORT_LIST_REQ {
  enabled?: boolean;
  categoryInfoList?: PortCategory[];
  /** 화면에 노출시킬 옵션의 형식을 가공하기 위해 사용 */
  transformToPortOption: (port: UserPort) => InputSearchWithPortOption<T>;
  /** GET /ports 응답 데이터 가공 */
  select?: (res: GET_USER_PORT_LIST_RES) => GET_USER_PORT_LIST_RES;
}

export type { UseGetPortOptionsProps };

export default function useGetPortOptions<T>({
  pointType,
  searchTerm,
  country,
  exceptCountry,
  portType,
  enabled,
  categoryInfoList,
  transformToPortOption,
  select,
}: UseGetPortOptionsProps<T>) {
  const { t } = useTranslation();

  const { data: allPortList = [], isLoading } = COMMON_QUERY.useGetUserPortList(
    {
      pointType,
      country,
      exceptCountry,
      portType,
      ...(searchTerm && { searchTerm }),
      enabled,
      select,
    }
  );

  /** 공항 포트옵션 리스트 */
  const airPortOptionList = useMemo(() => {
    const filteredAirPortList = allPortList.filter(
      ({ type }) => type !== "sea"
    );

    return [
      {
        category: {
          label: t("components:InputSearchWithPortOptions.공항"),
          keyword: "air",
        },
        optionList: filteredAirPortList.map(transformToPortOption),
      },
    ] as InputSearchWithCategoryPortOption<T>[];
  }, [allPortList, transformToPortOption, t]);

  /** 공항 제외 포트옵션 리스트 */
  const generalPortOptionList = useMemo(() => {
    const filteredSeaPortList = allPortList.filter(
      ({ type }) => type === "sea"
    );

    return [
      {
        category: {
          label: t("components:InputSearchWithPortOptions.항구"),
          keyword: "sea",
        },
        optionList: filteredSeaPortList.map(transformToPortOption),
      },
    ] as InputSearchWithCategoryPortOption<T>[];
  }, [allPortList, transformToPortOption, t]);

  /** 전체 포트옵션 리스트 */
  const portOptionList = useMemo(() => {
    /** 카테고리 정보가 없는 경우 -> 항구, 공항 순서로 옵션리스트 모두 반환 */
    if (!categoryInfoList?.length) {
      return [...generalPortOptionList, ...airPortOptionList];
    }

    /** 카테고리 정보가 있는 경우 -> categoryInfoList의 순서로 카테고리별 옵션리스트 반환 */
    const combinedCategoryOfPortOptionList = categoryInfoList
      .map(({ keyword }) => {
        const optionList = (
          keyword === "sea" ? generalPortOptionList : airPortOptionList
        ) as InputSearchWithCategoryPortOption<T>[];

        return (
          optionList.find(
            (option) =>
              "category" in option && option.category.keyword === keyword
          ) ?? null
        );
      })
      .filter(Boolean) as InputSearchWithCategoryPortOption<T>[];

    return combinedCategoryOfPortOptionList;
  }, [airPortOptionList, generalPortOptionList, categoryInfoList]);

  return { rawPortList: allPortList, portOptionList, isLoading };
}
