import SvgIconWrapper from "./SvgIconWrapper";

export default function WarehouseIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 33.8287V15.0246C1 13.4749 1.94584 12.0737 3.38866 11.4977L19.2934 5.13586C19.7447 4.95471 20.2553 4.95471 20.7066 5.13586L36.6131 11.4977C38.0559 12.0737 39 13.4749 39 15.0246V33.8287C39 34.6183 38.3647 35.2537 37.575 35.2537H34.725C33.9353 35.2537 33.3 34.6183 33.3 33.8287V18.1002C33.3 17.1027 32.4509 16.2002 31.4 16.2002H8.6C7.54906 16.2002 6.7 17.1027 6.7 18.1002V33.8287C6.7 34.6183 6.06172 35.2537 5.275 35.2537H2.425C1.63828 35.2537 1 34.6183 1 33.8287ZM10.025 35.2537C9.23531 35.2537 8.6 34.6183 8.6 33.8287V30.5037H31.4V33.8287C31.4 34.6183 30.7647 35.2537 29.975 35.2537H10.025ZM8.6 24.8037H31.4V28.6037H8.6V24.8037ZM8.6 18.1537H31.4V22.9037H8.6V18.1537Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}
